<template>
  <dash-page-new
      :title="$t('Certificates')"
      :root="$t('Dashboard')"
      icon="mdi-certificate"
      :no-content-condition="items.length === 0 "
      :no-content-text="$t('certificates.student.no_content')"
      :loading="loading"
  >

    <template #default>

      <div class="mt-5" >
        <v-row>
          <v-col  cols="12" md="6" lg="4" xl="3"    v-for="(item, i ) in items" :key="i">
            <v-sheet

                :color="wsLIGHTCARD"
                class="wsRoundedHalf mt-3">

              <div class="pa-3">
                <v-img transition="xxx" v-if="item.course_img" class="wsRoundedHalfTop" height="200"  :src="item.course_img" ></v-img>
                <v-img transition="xxx" v-else class="wsRoundedHalfTop" height="200"   src="@/assets/img/course_blank.jpg" ></v-img>
              </div>


              <div class="pa-3" >

                <v-sheet color="transparent shorten-text-two" >
                  <h4 style="font-weight: 700">{{  item.course_name }}</h4>
                </v-sheet>
                <h5 :style="`color : ${wsACCENT}`" class="cut-text font-weight-medium mt-2">
                  {{ $t('CertificateNumber') }}: <span class="font-weight-bold" >{{ item.number }}</span>
                </h5>
                <h5 :style="`color : ${wsACCENT}`" class="cut-text font-weight-medium ">
                  {{ $t('CourseCompletedAt') }}: <span class="font-weight-bold" >{{ PARSE_DATE(item.date, false , true) }}</span>
                </h5>

                <v-btn
                    v-if="!SM"
                    @click="loadCertificate(item)"
                    :color="wsACCENT"
                    elevation="0"
                    block
                    text class="noCaps mt-5 mb-1"
                >
                  {{ $t('Preview') }}

                </v-btn>

                <ft-select
                    @input="downloadCertificate(item , $event)"
                    :disabled="item.langs.length < 2"
                    :items="languagesSelect(item.langs)"
                >
                  <v-btn @click="item.langs.length < 2 ? downloadCertificate(item) : null"
                         :class="!SM ? 'mt-2' : 'mt-5'"
                         :color="wsATTENTION"
                         elevation="0"
                         block
                         dark class="noCaps mb-1"
                  >
                    {{ $t('Download') }}
                    <v-icon class="ml-3">mdi-link</v-icon>
                  </v-btn>
                </ft-select>



              </div>


            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </template>

    <template #dialog>

      <ws-dialog
          v-if="displayCertificate"
          v-model="displayCertificate"
          :title="$t('ViewCertificate')"
          width="850"
          no-navigation
          no-padding
      >

        <div class="d-flex justify-center ">

          <v-sheet min-height="566" min-width="800" style="position: relative" class="wsRoundedHalf mt-5" color="white">
            <certificate-viewer
                v-if="block.id"
                :block="block"
                :elements="elements"
                :certificate-record="selectedCertificateRecord"
            />
            <div v-else class="fill-height d-flex align-center justify-center">
              <v-progress-circular :color="wsACCENT" size="64" indeterminate />
            </div>

          </v-sheet>

        </div>

      </ws-dialog>

    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import certificateViewer from "@/components/pages/westudy/admin/course/courseCertificates/view/certificateViewer";
import languagesList from "@/mixins/languagesList";
import defaultConfig from "@/assets/json/avalon/defaultConfig.json";

export default {
  name: "StudentCertificates",
  components : {
    certificateViewer,
  },
  mixins : [languagesList],
  data() {
    return  {
      items : [],
      displayCertificate : false,
      selectedCertificateRecord : {},
      block : {},
      elements : [],
      loading : true,
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Course') , value : 'course_name' },
        { text : this.$t('Date') , value : 'date' , width : 10 },
        { value : 'action' , width : 10 }
      ]
    }
  },
  methods : {
    ...mapActions('certificates' , [
        'GET_STUDENT_CERTIFICATES',
        'GET_COURSE_CERTIFICATE_STUDENT',
        'DOWNLOAD_CERTIFICATE'
    ]),

    languagesSelect(langs) {
      if ( langs.length === 0 ) {
        return []
      }
      let items = []
      langs.forEach(lang => {
        items.push({ text : this.LANGUAGE_TEXT(lang) , value : lang })
      })

      return items
    },

    async downloadCertificate(item , lang = null) {
      if ( !lang && item.langs.length > 0 ) {
        lang = item.langs[0]
      }
      let data = {
        lang : lang,
        uuid : item.uuid
      }
      let result = await this.DOWNLOAD_CERTIFICATE(data)
      if ( !result ) {
        return
      }

      var url  = window.URL.createObjectURL(result);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'certificate.pdf';
      document.body.appendChild(a);
      a.click();

    },
    async loadCertificate(item) {
      this.selectedCertificateRecord = item
      this.displayCertificate = true
      this.block = {}
      this.elements = []
      let result = await this.GET_COURSE_CERTIFICATE_STUDENT(item.uuid)
      if ( !result ) {
        return
      }
      this.block = result.block
      this.elements = result.elements
    },

    async initPage() {
      let result = await this.GET_STUDENT_CERTIFICATES()
      if ( !result || result === true ) {
        return
      }
      this.items = result
    }
  },
  beforeMount() {
    this.$store.state.avalon.defaultConfig = defaultConfig
  },
  async mounted() {
    this.loading = true
    await this.initPage()
    this.loading = false
  }
}
</script>

<style scoped>

</style>